import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const Connexion = () => {
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const [pseudo, setPseudo] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false); // Nouvelle variable d'état pour suivre l'état de connexion
  const [affichagePopupCookie, setAffichagePopupCookie] = useState(true);
  const [wrongIdentifier, setWrongIdentifier] = useState(false);
  const [cookiesAcceptes, setCookiesAcceptes] = useState(false);
  const navigate = useNavigate(); // Initialisez useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${strapiBaseUrl}/api/auth/local`,
        {
          identifier: pseudo,
          password: password,
        },
        { withCredentials: true }
      );

      // Stockage du token JWT et du pseudo dans localStorage
      // localStorage.setItem('token', response.data.jwt);
      // localStorage.setItem('pseudo', pseudo);

      Cookies.set('token', response.data.jwt);
      setLoginSuccess(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error(
          'Erreur lors de la connexion : Identifiant ou mot de passe incorrect.'
        );
        setWrongIdentifier(true);
      } else {
        console.error('Erreur lors de la connexion : ', error);
      }
    }
  };

  useEffect(() => {
    // Vérifiez si un token existe dans localStorage pour maintenir la session connectée
    const token = Cookies.get('token');
    if (token) {
      setLoginSuccess(true);
    }
  }, []);

  useEffect(() => {
    // Vérification si il y a une connexion, redirection vers la page d'accueil
    if (loginSuccess) {
      setTimeout(() => {
        navigate('/BackOffice');
      }, 1000); // Ajout d'un délai de 1 seconde
    }
  }, [loginSuccess, navigate]);

  const acceptCookies = (e) => {
    setAffichagePopupCookie(false);
    setCookiesAcceptes(true);
  };

  const refuseCookies = (e) => {
    setAffichagePopupCookie(false);
  };

  const affichePopup = (e) => {
    setAffichagePopupCookie(true);
  };

  return (
    <>
      {loginSuccess ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <p className="text-center text-2xl">
            Connexion en cours... <br />
            Vous allez être redirigé vers la page d'accueil.
          </p>
        </div>
      ) : (
        <div className="mx-4 md:mx-40 my-20 md:my-40 flex flex-col items-center">
          {affichagePopupCookie && (
            <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white p-4 border border-gray-300 rounded shadow-2xl shadow-gray-400 z-50">
              <div className="text-center">
                <h2>Accepter les cookies</h2>
                <p>
                  Nous utilisons des cookies pour vous offrir la meilleure
                  expérience sur notre site web.
                </p>
                <button
                  className="mx-2 mt-2 bg-blue-500 text-white px-4 py-2 rounded cursor-pointer hover:bg-blue-600"
                  onClick={acceptCookies}
                >
                  Accepter
                </button>
                <button
                  className="mx-2 mt-2 bg-red-500 text-white px-4 py-2 rounded cursor-pointer hover:bg-red-600"
                  onClick={refuseCookies}
                >
                  Refuser
                </button>
              </div>
            </div>
          )}

          <h2 className="text-3xl text-center mb-6">Connexion</h2>
          <form
            className="flex flex-col bg-white shadow-2xl rounded px-8 pb-10 pt-10 mb-4 w-full max-w-md"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="pseudo"
              >
                Pseudo:
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                value={pseudo}
                onChange={(e) => setPseudo(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Mot de passe:
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {cookiesAcceptes ? (
              <button
                className="bg-yellow-500 hover:bg-yellow-800 text-black hover:text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline mt-5"
                type="submit"
              >
                Se connecter
              </button>
            ) : (
              <>
                <button
                  className="bg-gray-500 hover:bg-gray-800 text-black hover:text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline mt-5"
                  type="submit"
                  disabled
                >
                  Se connecter
                </button>
                <p className="text-center text-sm mt-2">
                  Pour vous connecter, veuillez accepter les cookies
                </p>
                <input
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold rounded cursor-pointer mt-2"
                  value="Recharger les cookies"
                  onClick={affichePopup}
                />
              </>
            )}

            <p className="mt-4">
              Pas de compte ? <br />
              Inscrivez-vous
              <a href="/Inscription" className="text-amber-600">
                {' '}
                ici
              </a>
            </p>
            {wrongIdentifier && (
              <p className="text-center text-red-600 font-bold mt-4">
                Identifiant ou mot de passe incorrect.
              </p>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default Connexion;
