import React from 'react';
import { useNavigate } from 'react-router-dom';

const CarteProduits = ({ index, idProduit, nom, prix, image }) => {
  const navigate = useNavigate();

  return (
    <div
      key={index}
      className="group relative w-[20rem] h-[30rem] md:w-[20rem] md:h-[30rem] p-4 z-1  shadow-lg rounded-lg"
      // className="group relative w-72 bg-[#f6edd8] rounded-md  p-4 "
    >
      <div className="w-full h-[20rem] overflow-hidden   lg:aspect-none  lg:h-80">
        <div
          className="w-full h-full bg-cover bg-center z-0 lg:hover:opacity-75 cursor-pointer duration-150"
          style={{
            backgroundImage: `url(${image})`,
          }}
          onClick={() => {
            navigate(`/Article/${idProduit}`);
            window.scrollTo(0, 0);
          }}
        >
          <div className="absolute inset-0 flex items-center hidden lg:flex justify-center opacity-0 hover:opacity-100 duration-300">
            <h2 className="hidden lg:block text-center font-bold text-2xl text-black">
              Voir le produit
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div>
          <h3 className="text-2xl font-medium ">{nom}</h3>
        </div>
        <p className="text-xl text-gray-600 font-medium ">{prix}€</p>
      </div>
    </div>
  );
};

export default CarteProduits;
