import React from 'react';
import { useNavigate } from 'react-router-dom';

const SwiperCard = ({ index, idProduit, image, nom, prix }) => {
  const navigate = useNavigate();

  return (
    <div
      key={index}
      className="bg-white rounded-lg shadow-md p-4 flex flex-col" // Utilisation de items-center pour centrer le contenu
    >
      <div className="flex justify-center mb-2">
        {' '}
        {/* Conteneur pour centrer l'image */}
        <img
          src={image}
          alt={nom}
          className="w-[16rem] h-[22rem] md:w-[28rem] md:h-[32rem] object-cover rounded-md"
          onClick={() => {
            navigate(`/Article/${idProduit}`);
            window.scrollTo(0, 0);
          }}
        />
      </div>
      <h3 className="text-lg font-semibold mb-1">{nom}</h3>{' '}
      {/* Alignement du texte au centre */}
      <p className="text-md text-gray-700 ">{prix} €</p>{' '}
      {/* Alignement du texte au centre */}
    </div>
  );
};

export default SwiperCard;
