import React from 'react';
import BlocAccueil from '../components/BlocAccueil';
import Nouveautes from '../components/Nouveautes';
import BlocCategories from '../components/BlocCategories';
import BlocMission from '../components/BlocMission';
import { Helmet } from 'react-helmet';

const Accueil = () => {
  return (
    <div>
      <Helmet>
        <title>Page d'accueil - Un Monde Qui S'Illumine</title>
        <meta
          name="description"
          content="Bienvenue sur le site Un Monde Qui S'illumine ! Découvrez mes créations et contactez-moi pour plus d'informations."
        />
        <meta
          name="keywords"
          content="objets personnalisés, décorations, créations, un monde qui s'illumine, accueil"
        />
      </Helmet>
      <BlocAccueil />
      <Nouveautes />
      <BlocCategories />
      <BlocMission />
    </div>
  );
};

export default Accueil;
