import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import '../src/styles/index.css';
import Navigation from './components/Navigation';
import Accueil from './pages/Accueil';
import Produits from './pages/Produits';
import Contact from './pages/Contact';
import MentionsLégales from './pages/MentionsLégales';
import Footer from './components/Footer';
import BlobScrollToTop from './components/BlobScrollToTop';
import Article from './pages/Article';
import BackOffice from './pages/BackOffice';
import Cookies from 'js-cookie';
import Connexion from './pages/Connexion';

const PrivateRoute = ({ element }) => {
  const token = Cookies.get('token');
  return token ? (
    element
  ) : (
    <Navigate
      to="/Connexion"
      replace
      state={{ from: window.location.pathname }}
    />
  );
};
function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <BlobScrollToTop />
        <Routes>
          <Route
            path="/BackOffice"
            element={<PrivateRoute element={<BackOffice />} />}
          />
          <Route path="/" element={<Accueil />} />
          <Route path="/Accueil" element={<Accueil />} />
          <Route path="/Produits" element={<Produits />} />
          <Route path="/Produits/:nomCategorie" element={<Produits />} />
          <Route path="/Article/:idProduit" element={<Article />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/MentionsLégales" element={<MentionsLégales />} />
          <Route path="/Connexion" element={<Connexion />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
