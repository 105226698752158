import React from 'react';
import { Helmet } from 'react-helmet';
import backgroundImage from '../images/imageaccueil.jpg';
import { useNavigate } from 'react-router-dom';

const BlocAccueil = () => {
  const navigate = useNavigate();

  return (
    <div
      className="h-128 flex items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Helmet>
        <title>Un Monde Qui S'Illumine - Accueil</title>
        <meta
          name="description"
          content="Découvrez un monde qui s'illumine avec nos créations d'objets personnalisés. Cliquez pour voir nos produits."
        />
        <meta
          name="keywords"
          content="objets personnalisés, créations, produits lumineux"
        />
        <meta property="og:title" content="Un Monde Qui S'Illumine - Accueil" />
        <meta
          property="og:description"
          content="Découvrez un monde qui s'illumine avec nos créations d'objets personnalisés. Cliquez pour voir nos produits."
        />
        <meta property="og:image" content={backgroundImage} />
        <meta property="og:url" content={window.location.href} />
        <meta
          name="twitter:title"
          content="Un Monde Qui S'Illumine - Accueil"
        />
        <meta
          name="twitter:description"
          content="Découvrez un monde qui s'illumine avec nos créations d'objets personnalisés. Cliquez pour voir nos produits."
        />
        <meta name="twitter:image" content={backgroundImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="text-center">
        <h1 className="text-4xl text-white font-bold">
          Un Monde Qui S'Illumine
        </h1>
        <p className="text-lg text-white mt-4">
          Création d'objets personnalisés
        </p>
        <button
          className="mt-8 border border-solid border-white hover:bg-white hover:bg-opacity-20 duration-150 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            navigate(`/Produits`);
            window.scrollTo(0, 0);
          }}
        >
          Voir les produits
        </button>
      </div>
    </div>
  );
};

export default BlocAccueil;
