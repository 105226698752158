import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';

const Footer = () => {
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const navigate = useNavigate();
  const [categories, setCategories] = useState({ data: [] });

  // Récupération des catégories depuis Strapi
  useEffect(() => {
    axios
      .get(`${strapiBaseUrl}/api/categories?populate=*`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Erreur', error);
      });
  }, []);

  const handleItemClickReseaux = (url) => {
    window.location.href = url;
  };

  return (
    <div className="bg-[#f6edd8] text-gray-800 p-6 md:p-10 border-t border-gray-300">
      {/* Section contact */}
      <div className="flex flex-col items-center text-center md:text-lg font-medium">
        <h3 className="mb-3 font-serif text-xl md:text-2xl">
          Une question concernant un produit ?
        </h3>
        <h3 className="font-serif text-xl md:text-2xl">
          Une commande à passer ?
        </h3>
        <button
          className="mt-5 md:mt-8 border border-solid border-black bg-gray-800 hover:bg-gray-600 duration-150 text-white font-bold py-2 px-6 md:px-8 tracking-widest"
          onClick={() => {
            navigate(`/Contact`);
            window.scrollTo(0, 0);
          }}
        >
          Contactez-moi
        </button>
      </div>

      {/* Section réseaux sociaux et liens */}
      <div className="flex flex-col md:flex-row justify-evenly items-start mt-10 md:mt-16">
        {/* Réseaux sociaux */}
        <div className="mb-6 md:mb-0">
          <h2 className="text-lg md:text-base lg:text-lg font-semibold mb-4 font-serif">
            Suivez-moi également sur Facebook !
          </h2>
          <div className="flex justify-center md:justify-center lg:justify-evenly gap-6">
            <FaFacebookSquare
              className="h-12 w-12 md:h-14 md:w-14 lg:h-16 lg:w-16 cursor-pointer hover:text-gray-600"
              onClick={() =>
                handleItemClickReseaux(
                  'https://www.facebook.com/profile.php?id=100095132774873'
                )
              }
            />
            {/* <FaInstagram
              className="h-12 w-12 md:h-14 md:w-14 lg:h-16 lg:w-16 cursor-pointer hover:text-gray-600"
              onClick={() =>
                handleItemClickReseaux('https://www.instagram.com/')
              }
            /> */}
          </div>
        </div>

        {/* Liens de navigation */}
        <div className="flex flex-col items-center md:items-start">
          <h2 className="text-lg md:text-xl font-semibold mb-4 font-serif">
            Navigation
          </h2>
          <div className="space-y-2">
            <h2
              className="cursor-pointer hover:text-gray-500 text-base md:text-lg"
              onClick={() => {
                navigate(`/Accueil`);
                window.scrollTo(0, 0);
              }}
            >
              Accueil
            </h2>
            <h2
              className="cursor-pointer hover:text-gray-500 text-base md:text-lg"
              onClick={() => {
                navigate(`/Produits`);
                window.scrollTo(0, 0);
              }}
            >
              Tous les produits
            </h2>
            <h2
              className="cursor-pointer hover:text-gray-500 text-base md:text-lg"
              onClick={() => {
                navigate(`/Contact`);
                window.scrollTo(0, 0);
              }}
            >
              Contact
            </h2>
            <h2
              className="cursor-pointer hover:text-gray-500 text-base md:text-lg"
              onClick={() => {
                navigate(`/MentionsLégales`);
                window.scrollTo(0, 0);
              }}
            >
              Mentions légales
            </h2>
          </div>
        </div>

        {/* Catégories dynamiques */}
        <div className="flex flex-col items-center md:items-start mt-6 md:mt-0">
          <h2 className="text-lg md:text-xl font-semibold mb-4 font-serif">
            Catégories
          </h2>
          <div className="space-y-2">
            {categories.data.map((categorie) => (
              <h3
                key={categorie.id}
                className="cursor-pointer hover:text-gray-500 text-base md:text-lg"
                onClick={() =>
                  navigate(`/Produits/${categorie.attributes.nom}`)
                }
              >
                {categorie.attributes.nom}
              </h3>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
