import React, { useState, useEffect } from 'react';
import { SlArrowUp } from 'react-icons/sl';
const BlobScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Afficher ou masquer le bouton en fonction du défilement
    function handleScroll() {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Fonction pour faire défiler vers le haut de la page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-5 right-0 lg:bottom-10 lg:left-auto lg:right-10 z-50 bg-gray-300 hover:bg-gray-500 duration-300 text-black px-4 py-4 rounded-full ${
        isVisible ? 'block' : 'hidden'
      }`}
    >
      <SlArrowUp size={24} />
    </button>
  );
};

export default BlobScrollToTop;
