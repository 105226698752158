import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BlocCategories = () => {
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${strapiBaseUrl}/api/categories?populate=*`)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.error('Erreur', error);
      });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full py-8 bg-white">
      <div className="w-11/12 max-w-4xl bg-[#f6edd8] rounded-lg shadow-lg p-6">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-center">
          Les catégories :
        </h2>
        <div className="flex flex-wrap justify-center">
          {categories.map((categorie) => (
            <div
              key={categorie.id}
              className="flex items-center text-xl md:text-2xl cursor-pointer m-2 p-2 bg-white rounded-lg shadow underline lg:no-underline hover:bg-gray-100 transition duration-300"
              onClick={() => {
                navigate(`/Produits/${categorie.attributes.nom}`);
                window.scrollTo(0, 0);
              }}
            >
              {/* <GoArrowRight className="mr-2 text-gray-500" /> */}
              <span className="text-gray-800 hover:text-gray-600">
                {categorie.attributes.nom}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlocCategories;
