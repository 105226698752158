import React from 'react';

const BlocMission = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="text-3xl lg:text-5xl text-center font-bold tracking-tight text-black my-10 ">
        Ma mission
      </h2>
      <p className="text-xl lg:text-2xl text-justify px-5 lg:px-0 lg:w-1/2 mb-10">
        Je suis passionnée par la création d’objets uniques qui racontent des
        histoires et reflètent la personnalité de chacun.
        <br />
        <br />
        Ma mission est de transformer des articles du quotidien en trésors
        personnalisés en vous proposant une vaste gamme de catégories pour
        répondre à vos besoins. <br />
        <br />
        Chez “Un Monde Qui S’Illumine", la personnalisation va au delà de la
        simple création d’objets, c’est une expression de votre individualité.
        <br />
        <br />
        Parcourez les différentes catégories et laissez moi donner vie à vos
        idées en transformant des objets ordinaires en pièces extraordinaires.
        <br />
        <br />
        N’hésitez pas à me contacter pour tout renseignements. Je suis pressée
        de pouvoir travailler avec vous, à illuminer votre monde 🙂.
      </p>
    </div>
  );
};

export default BlocMission;
