import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarteProduits from './CarteProduits';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'; // Importer les styles pour l'autoplay
import 'swiper/css/pagination'; // Importer les styles pour la pagination
import { Autoplay, Pagination } from 'swiper/modules'; // Importer les modules Autoplay et Pagination
import SwiperCard from './SwiperCard';
const Nouveautes = () => {
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const navigate = useNavigate();
  const [derniersProduit, setDerniersProduit] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === derniersProduit.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? derniersProduit.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `${strapiBaseUrl}/api/articles?populate=*`
        );
        const articlesTries = response.data.data.sort((a, b) => b.id - a.id);
        const derniersProduit = articlesTries.slice(0, 3);
        setDerniersProduit(derniersProduit);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article : ", error);
      }
    };
    fetchArticles();
  }, []);

  console.log(derniersProduit);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-20 py-16 md:px-20 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-3xl md:text-4xl lg:text-5xl text-center font-bold tracking-tight text-black mb-20">
          Nouveautés !
        </h2>
        <div className="hidden mt-6 lg:grid grid-cols-1 justify-items-center gap-x-8 gap-y-10 lg:grid-cols-3">
          {derniersProduit &&
            derniersProduit.map((dernierProduit, index) => {
              const photos = dernierProduit.attributes.photo?.data;
              const firstImage =
                Array.isArray(photos) && photos.length > 0 ? photos[0] : null;

              // Sélectionner le format d'image préféré
              const imageUrl =
                firstImage?.attributes.formats.large?.url ||
                firstImage?.attributes.formats.medium?.url ||
                firstImage?.attributes.formats.small?.url ||
                firstImage?.attributes.url;

              const fullImageUrl = `${strapiBaseUrl}${imageUrl}`;

              return (
                dernierProduit.attributes && (
                  <CarteProduits
                    key={index}
                    idProduit={dernierProduit.id}
                    nom={dernierProduit.attributes.nom}
                    prix={dernierProduit.attributes.prix}
                    image={fullImageUrl}
                  />
                )
              );
            })}
        </div>
        <div className="flex justify-center lg:hidden mx-auto md:w-[30rem]">
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            autoplay={{
              delay: 3000, // Délai entre les slides (3000ms = 3s)
              disableOnInteraction: false, // Continuer l'autoplay après interaction
            }}
            pagination={{
              clickable: true, // Pagination avec clic
            }}
            speed={1000} // Vitesse de transition plus lente (1000ms = 1s)
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 16,
              },
            }}
            // modules={Pagination} // Activer les modules Autoplay et Pagination
            modules={[Autoplay, Pagination]} // Activer les modules Autoplay et Pagination
          >
            {derniersProduit &&
              derniersProduit.map((dernierProduit, index) => {
                const photos = dernierProduit.attributes.photo?.data;
                const firstImage =
                  Array.isArray(photos) && photos.length > 0 ? photos[0] : null;

                // Sélectionner le format d'image préféré
                const imageUrl =
                  firstImage?.attributes.formats.large?.url ||
                  firstImage?.attributes.formats.medium?.url ||
                  firstImage?.attributes.formats.small?.url ||
                  firstImage?.attributes.url;

                const fullImageUrl = `${strapiBaseUrl}${imageUrl}`;
                return (
                  <SwiperSlide
                    key={dernierProduit.id}
                    className="flex justify-center items-center py-10"
                  >
                    {Array.isArray(dernierProduit.attributes.photo?.data) &&
                      dernierProduit.attributes.photo.data.length > 0 && (
                        <SwiperCard
                          key={dernierProduit.id}
                          index={dernierProduit.id}
                          idProduit={dernierProduit.id}
                          nom={dernierProduit.attributes.nom}
                          prix={dernierProduit.attributes.prix}
                          image={`${strapiBaseUrl}${
                            dernierProduit.attributes.photo.data[0]?.attributes
                              .formats.large?.url ||
                            dernierProduit.attributes.photo.data[0]?.attributes
                              .formats.medium?.url ||
                            dernierProduit.attributes.photo.data[0]?.attributes
                              .formats.small?.url ||
                            dernierProduit.attributes.photo.data[0]?.attributes
                              .url
                          }`}
                        />
                      )}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Nouveautes;
