import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState();
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  useEffect(() => {
    axios
      .get(`${strapiBaseUrl}/api/categories?populate=*`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Erreur', error);
      });
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Fermer le menu lorsque l'utilisateur change de page
    setMenuOpen(false);
  }, [location.pathname]);

  return (
    <nav className="pb-4 bg-[#f6edd8] flex flex-col items-center shadow-xl">
      {/* Première ligne - Logo au centre */}
      <div className="flex items-center justify-center cursor-pointer">
        <img
          src="/logo.png"
          alt="Logo"
          className="w-32"
          onClick={() => navigate('/Accueil')}
        />
      </div>

      {/* MENU LAPTOP DESKTOP */}
      <div className="hidden items-center justify-center w-full lg:flex">
        {/* Accueil */}
        <h2
          onClick={() => navigate('/Accueil')}
          className="text-gray-800 text-lg font-medium px-4 py-2 hover:text-gray-400 mx-4 duration-300 cursor-pointer"
        >
          ACCUEIL
        </h2>

        {/* Produits */}
        <div
          className="relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button className="text-lg font-medium text-gray-800 px-4 py-2 hover:text-gray-400 duration-300 mx-4">
            PRODUITS
          </button>
          {isOpen && (
            <div className="absolute top-full left-0 mt-0 px-2 py-4 border-2-gray rounded shadow-md bg-gray-50 z-10">
              <h2
                className="text-center text-lg font-medium text-gray-800 inline-block duration-300 hover:text-gray-400 cursor-pointer"
                style={{ width: 'fit-content' }}
                onClick={() => {
                  navigate(`/Produits`);
                  window.scrollTo(0, 0);
                }}
              >
                Tous les produits
              </h2>
              {categories &&
                categories.data.map((categorie) => (
                  <div
                    key={categorie.id}
                    className="w-56 mt-2" // Définissez la largeur maximale du conteneur
                  >
                    <h2
                      className="text-center text-lg font-medium text-gray-800 inline-block duration-300 hover:text-gray-400 cursor-pointer"
                      style={{ width: 'fit-content' }}
                      onClick={() => {
                        navigate(`/Produits/${categorie.attributes.nom}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {categorie.attributes.nom}
                    </h2>
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* Contact */}
        <button
          className="text-lg font-medium text-gray-800 px-4 py-2 duration-300 hover:text-gray-400 mx-4"
          onClick={() => navigate('/Contact')}
        >
          CONTACT
        </button>
      </div>

      {/* MENU TABLETTE PHONE */}
      <div className="flex items-center justify-center w-full lg:hidden">
        {/* Accueil */}
        <h2
          onClick={() => navigate('/Accueil')}
          className="text-gray-800 text-lg font-medium py-2 md:px-4 mx-4 duration-300 cursor-pointer"
        >
          ACCUEIL
        </h2>

        {/* Produits */}
        <div className="relative">
          <button
            onClick={toggleMenu}
            className="text-lg font-medium text-gray-800 py-2 md:px-4 duration-300 mx-4"
          >
            PRODUITS
          </button>
        </div>

        {/* Contact */}
        <button
          className="text-lg font-medium text-gray-800 py-2 md:px-4 duration-300 mx-4"
          onClick={() => navigate('/Contact')}
        >
          CONTACT
        </button>
      </div>

      {menuOpen && (
        <div className="absolute w-full left-0 mt-44 px-2 py-4 border-2-gray rounded shadow-md bg-gray-50 z-10">
          <h2
            className="text-center text-lg font-medium text-gray-800 inline-block duration-300 cursor-pointer"
            style={{ width: 'fit-content' }}
            onClick={() => {
              navigate(`/Produits`);
              window.scrollTo(0, 0);
            }}
          >
            Tous les produits
          </h2>
          {categories &&
            categories.data.map((categorie) => (
              <div
                key={categorie.id}
                className="w-56 mt-2" // Définissez la largeur maximale du conteneur
              >
                <h2
                  className="text-center text-lg font-medium text-gray-800 inline-block duration-300 cursor-pointer"
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    navigate(`/Produits/${categorie.attributes.nom}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  {categorie.attributes.nom}
                </h2>
              </div>
            ))}
        </div>
      )}
    </nav>
  );
};

export default Navigation;
