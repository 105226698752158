import React from 'react';

const MentionsLégales = () => {
  return (
    <div className="px-4 sm:px-8 md:px-12 lg:px-20 xl:px-40 py-8 text-gray-800">
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-8">
        Mentions Légales
      </h1>

      <div className="space-y-8">
        {/* Informations Générales */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            1. Informations Générales
          </h2>
          <p className="text-sm md:text-base">
            Conformément aux dispositions des articles 6-III et 19 de la Loi n°
            2004-575 du 21 juin 2004 pour la confiance dans l'économie
            numérique, il est précisé aux utilisateurs du site{' '}
            <a
              href="https://www.unmondequisillumine.fr"
              className="text-blue-600 hover:underline"
            >
              www.unmondequisillumine.fr
            </a>{' '}
            l'identité des différents intervenants dans le cadre de sa
            réalisation et de son suivi.
          </p>
          <p className="mt-2 text-sm md:text-base">
            <strong>Propriétaire :</strong> Mélissa Fréville
          </p>
          <p className="mt-2 text-sm md:text-base">
            <strong>Adresse :</strong>9a rue de la justice, 62880
            Vendin-le-vieil
          </p>
          <p className="mt-2 text-sm md:text-base">
            <strong>Email :</strong>{' '}
            <a
              href="mailto:mondequisillumine@gmail.com"
              className="text-blue-600 hover:underline"
            >
              mondequisillumine@gmail.com
            </a>
          </p>
          <p className="mt-2 text-sm md:text-base">
            <strong>Responsable de la publication :</strong> Mélissa Fréville
          </p>
          <p className="mt-2 text-sm md:text-base">
            <strong>SIREN :</strong> 983 492 547
          </p>{' '}
          <p className="mt-2 text-sm md:text-base">
            <strong>SIRET :</strong> 983 492 547 00010
          </p>
        </section>

        {/* Hébergement */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            2. Hébergement
          </h2>
          <p className="text-sm md:text-base">
            L'hébergement du site est assuré par <strong>Hostinger</strong>,
            situé à l'adresse suivante :
            <br />
            Jonavos g. 60C, Kaunas 44192, Lituanie
          </p>
        </section>

        {/* Propriété Intellectuelle */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            3. Propriété Intellectuelle
          </h2>
          <p className="text-sm md:text-base">
            L'ensemble des contenus présents sur ce site (textes, images,
            vidéos, logos, etc.) est protégé par les lois en vigueur sur la
            propriété intellectuelle. Toute reproduction ou utilisation des
            contenus est interdite sans autorisation préalable.
          </p>
        </section>

        {/* Données Personnelles */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            4. Données Personnelles
          </h2>
          <p className="text-sm md:text-base">
            Ce site ne collecte pas de données personnelles à des fins
            commerciales. Les informations recueillies via le formulaire de
            contact (nom, email, message) sont uniquement utilisées pour
            répondre aux demandes des utilisateurs. Ces informations ne sont pas
            stockées de manière prolongée et ne sont jamais partagées avec des
            tiers.
          </p>
        </section>

        {/* Responsabilité */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            5. Responsabilité
          </h2>
          <p className="text-sm md:text-base">
            Mélissa Fréville ne saurait être tenue pour responsable des erreurs
            ou omissions sur le site, de l'indisponibilité temporaire du site,
            ou des dommages directs ou indirects pouvant résulter de
            l'utilisation de ce site.
          </p>
        </section>

        {/* Liens Hypertextes */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            6. Liens Hypertextes
          </h2>
          <p className="text-sm md:text-base">
            Le site <strong>www.unmondequisillumine.fr</strong> peut contenir
            des liens vers d'autres sites. "Un Monde Qui S'Illumine" n'exerce
            aucun contrôle sur ces sites et ne peut être tenue responsable de
            leur contenu.
          </p>
        </section>

        {/* Modifications */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            7. Modifications
          </h2>
          <p className="text-sm md:text-base">
            "Un Monde Qui S'Illumine" se réserve le droit de modifier les
            présentes mentions légales à tout moment. Les utilisateurs sont
            invités à consulter régulièrement cette page pour prendre
            connaissance des éventuelles modifications.
          </p>
        </section>

        {/* Droit Applicable */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">
            8. Droit Applicable
          </h2>
          <p className="text-sm md:text-base">
            Les présentes mentions légales sont régies par le droit français.
            Tout litige relatif à l'utilisation du site sera soumis aux
            tribunaux compétents.
          </p>
        </section>

        {/* Cookies */}
        <section>
          <h2 className="text-xl md:text-2xl font-semibold mb-2">9. Cookies</h2>
          <p className="text-sm md:text-base">
            Ce site n'utilise aucun cookie à des fins de suivi, de
            personnalisation ou de publicité.
          </p>
        </section>
      </div>
    </div>
  );
};

export default MentionsLégales;
