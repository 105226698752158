import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const AdminPage = () => {
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrix, setProductPrix] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productImages, setProductImages] = useState([]); // État pour les images multiples

  const [categoryName, setCategoryName] = useState('');
  const [activeTab, setActiveTab] = useState('products');
  const [searchQuery, setSearchQuery] = useState(''); // Ajout de l'état pour la recherche
  const [sortOrder, setSortOrder] = useState('desc'); // État pour l'ordre de tri ('asc' ou 'desc')
  const [selectedCategoryId, setSelectedCategoryId] = useState(''); // Ajout de l'état pour la catégorie sélectionnée

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, [searchQuery, sortOrder, selectedCategoryId]);

  const fetchProducts = () => {
    let url = `${strapiBaseUrl}/api/articles?populate=*`;

    if (searchQuery) {
      url += `&filters[nom][$containsi]=${searchQuery}`;
    }

    axios
      .get(url)
      .then((response) => {
        const sortedProducts = sortProducts(response.data.data, sortOrder);
        setProducts(sortedProducts);
      })
      .catch((error) => {
        console.error('Erreur', error);
      });
  };

  const fetchCategories = () => {
    axios
      .get(`${strapiBaseUrl}/api/categories?populate=*`)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.error('Erreur', error);
      });
  };
  console.log(categories);
  // Fonction pour trier les produits par date
  const sortProducts = (data, order) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.attributes.publishedAt);
      const dateB = new Date(b.attributes.publishedAt);

      if (order === 'asc') {
        return dateA - dateB; // Tri ascendant (du plus ancien au plus récent)
      } else {
        return dateB - dateA; // Tri descendant (du plus récent au plus ancien)
      }
    });
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleProductSubmit = async (event) => {
    event.preventDefault();
    let imageIds = [];
    const token = Cookies.get('token');

    if (!token) {
      console.error("Token d'authentification manquant");
      return;
    }

    // Upload des images si des fichiers ont été sélectionnés
    if (productImages && productImages.length > 0) {
      const formData = new FormData();
      productImages.forEach((file) => formData.append('files', file));

      try {
        const uploadRes = await axios.post(
          `${strapiBaseUrl}/api/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Récupérer les IDs des images uploadées
        imageIds = uploadRes.data.map((image) => image.id);
      } catch (error) {
        console.error('Erreur lors du téléchargement des images', error);
        return;
      }
    } else if (selectedProduct?.attributes?.photo) {
      // Conserver l'ID des images actuelles si aucune nouvelle image n'est téléchargée
      imageIds = selectedProduct.attributes.photo.data.map((photo) => photo.id);
    }

    // Construire les données du produit
    const productData = {
      nom: productName,
      details: productDescription,
      prix: productPrix,
      category: productCategory || null,
      photo: imageIds, // Ajoute les IDs des images
    };

    try {
      if (selectedProduct) {
        await axios.put(
          `${strapiBaseUrl}/api/articles/${selectedProduct.id}`,
          { data: productData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(
          `${strapiBaseUrl}/api/articles`,
          { data: productData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      fetchProducts();
      setSelectedProduct(null);
      setProductName('');
      setProductDescription('');
      setProductPrix('');
      setProductCategory('');
      setProductImages([]);

      window.location.reload();
    } catch (error) {
      console.error('Erreur lors de la soumission du produit', error);
    }
  };

  console.log(productCategory);
  const handleCategorySubmit = (event) => {
    event.preventDefault();
    const categoryData = {
      nom: categoryName,
    };

    const token = Cookies.get('token'); // Assurez-vous que le token est bien récupéré

    const request = selectedCategoryId
      ? axios.put(
          `${strapiBaseUrl}/api/categories/${selectedCategoryId}`,
          { data: categoryData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      : axios.post(
          `${strapiBaseUrl}/api/categories`,
          { data: categoryData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

    request
      .then((response) => {
        setCategoryName('');
        setSelectedCategoryId(''); // Remettre selectedCategoryId à vide après soumission
        fetchCategories();
      })
      .catch((error) => {
        console.error('Erreur lors du traitement de la catégorie :', error);
      });
  };

  const handleProductDelete = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
      const token = Cookies.get('token'); // Assurez-vous que le token est bien récupéré

      axios
        .delete(`${strapiBaseUrl}/api/articles/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          fetchProducts();
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        })
        .catch((error) => {
          console.error('Erreur', error);
        });
    }
  };

  const handleCategoryDelete = (id) => {
    if (
      window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?')
    ) {
      const token = Cookies.get('token'); // Assurez-vous que le token est bien récupéré

      axios
        .delete(`${strapiBaseUrl}/api/categories/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          fetchCategories();
        })
        .catch((error) => {
          console.error('Erreur', error);
        });
    }
  };

  const handleProductEdit = (product) => {
    setSelectedProduct(product);
    setProductName(product.attributes.nom || '');
    setProductDescription(product.attributes.details || '');
    setProductPrix(product.attributes.prix || '');
    setProductCategory(product.attributes.categorie?.data?.id || '');
    setProductImages(null);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleCategoryEdit = (category) => {
    setSelectedCategoryId(category.id); // Mettre à jour selectedCategoryId
    setCategoryName(category.attributes.nom || '');
  };

  const handleCancelEdit = () => {
    setSelectedProduct(null);
    setSelectedCategoryId(''); // Remettre selectedCategoryId à vide
    setProductName('');
    setProductDescription('');
    setProductPrix('');
    setProductCategory('');
    setProductImages(null);
    setCategoryName('');
  };

  const handleImagesChange = (event) => {
    const files = Array.from(event.target.files);
    setProductImages(files);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('pseudo');
    localStorage.removeItem('token');
    localStorage.removeItem('pseudo');
    window.location.href = '/';
  };
  console.log(products);
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Page</h1>

      <div className="flex space-x-4 mb-8">
        <button
          className="px-4 py-2 bg-yellow-500 hover:bg-yellow-800 text-black hover:text-white rounded-md"
          onClick={handleLogout}
        >
          Déconnexion
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === 'products' ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab('products')}
        >
          Gérer les Produits
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === 'categories'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200'
          }`}
          onClick={() => setActiveTab('categories')}
        >
          Gérer les Catégories
        </button>
      </div>

      {/* Ajout du champ de recherche et du tri */}
      {activeTab === 'products' && (
        <div className="flex space-x-4 mb-8">
          <input
            type="text"
            placeholder="Rechercher par nom"
            value={searchQuery}
            onChange={handleSearchQueryChange}
            className="border p-2 rounded-md"
          />

          <select
            className="border p-2 rounded-md"
            value={sortOrder}
            onChange={handleSortOrderChange}
          >
            <option value="desc">Du plus récent au plus ancien</option>
            <option value="asc">Du plus ancien au plus récent</option>
          </select>
        </div>
      )}

      {activeTab === 'products' && (
        <div>
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">
              Ajouter/Modifier un produit
            </h2>
            <form onSubmit={handleProductSubmit} className="mb-4">
              <input
                type="text"
                placeholder="Nom du produit"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                className="border p-2 mb-2 w-full"
              />
              <input
                type="text"
                placeholder="Prix du produit"
                value={productPrix}
                onChange={(e) => setProductPrix(e.target.value)}
                className="border p-2 mb-2 w-full"
              />
              <textarea
                placeholder="Détail du produit"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                className="border p-2 mb-2 w-full"
              />
              <select
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
                className="border p-2 mb-2 w-full"
              >
                <option value="">Sélectionnez une catégorie</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.attributes.nom}
                  </option>
                ))}
              </select>
              <input
                type="file"
                multiple
                onChange={handleImagesChange}
                className="border p-2 mb-2 w-full"
              />

              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                {selectedProduct ? 'Mettre à jour' : 'Ajouter le produit'}
              </button>
              {selectedProduct && (
                <button
                  type="button"
                  className="px-4 py-2 bg-red-500 text-white rounded-md ml-2"
                  onClick={handleCancelEdit}
                >
                  Annuler
                </button>
              )}
            </form>
          </div>

          <div className="mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="border p-4 rounded-lg shadow-lg flex flex-col justify-between"
                  style={{ minHeight: '400px' }}
                >
                  <div>
                    {/* Swiper pour afficher plusieurs images */}
                    {Array.isArray(product?.attributes?.photo?.data) &&
                    product.attributes.photo.data.length > 0 ? (
                      <Swiper
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        className="w-full h-48 mb-4 rounded"
                      >
                        {product.attributes.photo.data.map((photo) => {
                          const imageUrl = `${strapiBaseUrl}${photo.attributes.url}`;
                          return (
                            <SwiperSlide key={photo.id}>
                              <img
                                src={imageUrl}
                                alt={product.attributes.nom}
                                className="w-full h-48 object-cover rounded"
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <p>Aucune image disponible</p>
                    )}

                    <h3 className="text-lg font-semibold mb-2">
                      {product?.attributes?.nom || 'Nom non disponible'}
                    </h3>
                    <p className="mb-2" style={{ minHeight: '60px' }}>
                      {product?.attributes?.details ||
                        'Détails non disponibles'}
                    </p>
                    <p className="mb-2">
                      Prix: {product?.attributes?.prix || 'N/A'}€
                    </p>
                    <p className="mb-2">
                      Catégorie:{' '}
                      {product?.attributes?.category?.data?.attributes?.nom ||
                        'N/A'}
                    </p>
                  </div>
                  <div className="flex justify-between mt-auto">
                    <button
                      onClick={() => handleProductEdit(product)}
                      className="px-2 py-1 bg-gray-500 text-white rounded-md"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => handleProductDelete(product.id)}
                      className="px-2 py-1 bg-red-500 text-white rounded-md"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {activeTab === 'categories' && (
        <div>
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">
              Ajouter/Modifier une catégorie
            </h2>
            <form onSubmit={handleCategorySubmit} className="mb-4">
              <input
                type="text"
                placeholder="Nom de la catégorie"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="border p-2 mb-2 w-full"
              />
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                {selectedCategoryId ? 'Mettre à jour' : 'Ajouter la catégorie'}
              </button>
              {selectedCategoryId && (
                <button
                  type="button"
                  className="px-4 py-2 bg-red-500 text-white rounded-md ml-2"
                  onClick={handleCancelEdit}
                >
                  Annuler
                </button>
              )}
            </form>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-2">Liste des catégories</h2>
            <table className="w-full border-collapse border">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Nom</th>
                  <th className="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <tr key={category.id} className="hover:bg-gray-100">
                    <td className="border px-4 py-2">
                      {category.attributes.nom}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleCategoryEdit(category)}
                        className="px-2 py-1 bg-yellow-500 text-white rounded-md mr-2"
                      >
                        Modifier
                      </button>
                      <button
                        onClick={() => handleCategoryDelete(category.id)}
                        className="px-2 py-1 bg-red-500 text-white rounded-md"
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
