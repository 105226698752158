import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

const Article = () => {
  const navigate = useNavigate();
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const { idProduit } = useParams();
  const [article, setArticle] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `${strapiBaseUrl}/api/articles/${idProduit}?populate=photo`
        );
        setArticle(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article : ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, [idProduit, strapiBaseUrl]); // Ajout de idProduit et strapiBaseApiUrl dans les dépendances

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (!article || !article.attributes) {
    return <p>Aucun article trouvé.</p>;
  }

  // Récupérer toutes les images
  const photos = article.attributes.photo?.data;
  const imageSlides = Array.isArray(photos)
    ? photos.map((photo) => {
        const imageUrl =
          photo.attributes.formats.large?.url ||
          photo.attributes.formats.medium?.url ||
          photo.attributes.formats.small?.url ||
          photo.attributes.url;
        const fullImageUrl = `${strapiBaseUrl}${imageUrl}`;
        return (
          <SwiperSlide key={photo.id}>
            <img
              src={fullImageUrl}
              alt={article.attributes.nom}
              className="w-full h-80 md:h-[32rem] lg:h-[40rem] object-cover"
            />
          </SwiperSlide>
        );
      })
    : null;

  return (
    <div className="px-4 py-10 sm:px-8 md:px-16 lg:px-20 2xl:px-60 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4 ">
      <Helmet>
        <title>{article.attributes.nom} - Un Monde Qui S'Illumine</title>
        <meta name="description" content={`${article.attributes.details}...`} />
      </Helmet>

      {/* Swiper pour afficher toutes les photos */}
      <div className="xl:col-span-2">
        <Swiper
          pagination={{
            clickable: true,
            dynamicBullets: true, // Ajoute des points dynamiques pour un meilleur affichage
          }}
          modules={[Pagination]}
          className="h-80 md:h-[32rem] lg:h-[40rem]"
        >
          {imageSlides}
        </Swiper>
      </div>

      <div className="xl:col-span-2 2xl:pr-28">
        <h2 className="text-2xl font-medium tracking-wider">
          {article.attributes.nom}
        </h2>
        <h3 className="mt-5 text-2xl font-medium tracking-wider">
          {article.attributes.prix} €
        </h3>
        <h3 className="mt-10 tracking-wider">{article.attributes.details}</h3>
        <h3 className="mt-10 tracking-wider font-bold">
          Au prix du produit s’ajoute les frais de port. Ils vous seront
          communiqués lors de notre échange par mail.
        </h3>
        <div className="flex justify-center lg:justify-start">
          <button
            className="mt-20 border border-solid border-black bg-gray-800 hover:bg-gray-600 duration-150 text-white font-bold py-2 px-8 tracking-widest"
            onClick={() => {
              navigate(`/Contact`);
              window.scrollTo(0, 0);
            }}
          >
            Commander le produit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Article;
