import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IoFilterOutline } from 'react-icons/io5';
import { RxCross2 } from 'react-icons/rx';
import { useParams } from 'react-router-dom';
import CarteProduits from '../components/CarteProduits';
import { Helmet } from 'react-helmet';

const Produits = () => {
  const strapiBaseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const navigate = useNavigate();
  const { nomCategorie } = useParams();
  const [categories, setCategories] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [articles, setArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // Ajout de l'état pour la recherche
  const [sortOrder, setSortOrder] = useState('desc'); // Ajout de l'état pour le tri
  const filterRef = useRef(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `${strapiBaseUrl}/api/articles?populate=*`
        );
        const sortedArticles = sortArticles(response.data.data, sortOrder);
        setArticles(sortedArticles);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article : ", error);
      }
    };
    fetchArticles();
  }, [sortOrder]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${strapiBaseUrl}/api/categories?populate=*`
        );
        setCategories(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article : ", error);
      }
    };
    fetchCategories();
  }, [sortOrder]);

  // Fonction pour trier les articles par date
  const sortArticles = (data, order) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.attributes.publishedAt);
      const dateB = new Date(b.attributes.publishedAt);

      if (order === 'asc') {
        return dateA - dateB; // Tri ascendant (du plus ancien au plus récent)
      } else {
        return dateB - dateA; // Tri descendant (du plus récent au plus ancien)
      }
    });
  };

  // Fonction pour basculer l'affichage du menu des filtres
  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  // Fonction pour fermer le menu si on clique en dehors
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fonction pour gérer le changement de catégorie via les cases à cocher
  const handleCheckboxChange = (categoryName) => {
    window.scrollTo(0, 0);
    navigate(`/Produits/${categoryName}`);
    setShowMenu(false); // Ferme le menu après sélection de la catégorie
  };

  // Fonction pour annuler la sélection de la catégorie
  const annulationSelection = () => {
    window.scrollTo(0, 0);
    navigate('/Produits');
    setShowMenu(false); // Ferme le menu après annulation de la sélection
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  return (
    <div className="flex flex-col px-10 lg:px-20 xl:px-30">
      <Helmet>
        <title>Page d'accueil - Un Monde Qui S'Illumine</title>
        <meta
          name="description"
          content="Bienvenue sur le site Un Monde Qui S'illumine ! Découvrez mes créations et contactez-moi pour plus d'informations."
        />
        <meta
          name="keywords"
          content="objets personnalisés, décorations, créations, un monde qui s'illumine,produits"
        />
      </Helmet>
      <div className="text-2xl font-medium text-center mt-5 mb-10 uppercase">
        {nomCategorie ? <>{nomCategorie}</> : <>Tous les produits</>}
      </div>

      {/* Menu de filtres */}
      <div className="relative">
        <button
          className={`z-50 left-0 px-4 py-4 rounded-full bg-gray-300 text-black`}
          onClick={toggleMenu}
        >
          {showMenu ? <RxCross2 size={20} /> : <IoFilterOutline size={20} />}
        </button>

        {showMenu && (
          <div
            className="absolute z-50 w-full bg-white border border-gray-200 rounded-lg shadow-lg mt-2"
            ref={filterRef}
          >
            <div className="p-4">
              <input
                type="text"
                placeholder="Rechercher par nom"
                value={searchQuery}
                onChange={handleSearchQueryChange}
                className="border p-2 rounded-md mb-4 w-full"
              />

              <select
                className="border p-2 rounded-md mb-4 w-full"
                value={sortOrder}
                onChange={handleSortOrderChange}
              >
                <option value="desc">Du plus récent au plus ancien</option>
                <option value="asc">Du plus ancien au plus récent</option>
              </select>
            </div>

            {categories &&
              categories.map((categorie) => (
                <div
                  key={categorie.id}
                  className="p-2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <label className="flex cursor-pointer items-center gap-2 text-xl text-black">
                    <input
                      type="radio"
                      name="categorie"
                      id={categorie.id}
                      checked={nomCategorie === categorie.attributes.nom}
                      onChange={() =>
                        handleCheckboxChange(categorie.attributes.nom)
                      }
                    />
                    {categorie.attributes.nom}
                  </label>
                </div>
              ))}

            {nomCategorie && (
              <div className="p-4">
                <button
                  className="mt-4 border border-solid border-black bg-gray-800 hover:bg-gray-600 duration-150 text-white font-medium py-2 px-2 rounded w-full"
                  onClick={annulationSelection}
                >
                  Annuler la sélection
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <div className="col-span-5 grid grid-cols-1 md:grid-cols-2 justify-items-center gap-x-4 gap-y-8 lg:grid-cols-3 2xl:grid-cols-3">
          {articles && articles.length > 0 ? (
            articles
              .filter(
                (article) =>
                  (!nomCategorie ||
                    article.attributes.category.data.attributes.nom ===
                      nomCategorie) &&
                  (!searchQuery ||
                    article.attributes.nom
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))
              )
              .map((article) => {
                const photos = article.attributes.photo?.data;
                const firstImage =
                  Array.isArray(photos) && photos.length > 0 ? photos[0] : null;

                const imageUrl =
                  firstImage?.attributes.formats.large?.url ||
                  firstImage?.attributes.formats.medium?.url ||
                  firstImage?.attributes.formats.small?.url ||
                  firstImage?.attributes.url;

                const fullImageUrl = `${strapiBaseUrl}${imageUrl}`;

                return (
                  <CarteProduits
                    key={article.id}
                    idProduit={article.id}
                    nom={article.attributes.nom}
                    prix={article.attributes.prix}
                    image={fullImageUrl} // Utilisation de fullImageUrl pour l'image
                  />
                );
              })
          ) : (
            <p>Aucun produit disponible.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Produits;
