import React, { useState } from 'react';
import { FaPhone } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import { send } from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  // Déclaration des états
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Fonction de validation d'email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Fonction de validation du formulaire
  const validateForm = () => {
    let formErrors = {};

    if (!nom.trim() || /[^a-zA-Z\s]/.test(nom) || nom.length < 2) {
      formErrors.nom =
        'Veuillez entrer un nom valide sans chiffres et au moins 2 caractères.';
    }

    if (!email.trim() || !validateEmail(email)) {
      formErrors.email = 'Veuillez entrer une adresse email valide.';
    }

    if (!message.trim() || message.length < 2) {
      formErrors.message =
        "Veuillez entrer un message d'au moins 2 caractères.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Gestion du changement de valeur du captcha
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert('Veuillez compléter le reCAPTCHA.');
      return;
    }

    if (validateForm()) {
      setIsSending(true);
      try {
        await send(
          'service_m9wu122', // Remplacez par votre ID de service EmailJS
          'template_m3qhc1e', // Remplacez par votre ID de template EmailJS
          { nom, email, message },
          'NCk1ZHjCMqFPL5Zeg' // Remplacez par votre clé utilisateur EmailJS
        );
        setSuccessMessage('Votre message a été envoyé avec succès !');
        setNom('');
        setEmail('');
        setMessage('');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } catch (error) {
        console.error("Erreur d'envoi :", error);
        setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
      } finally {
        setIsSending(false);
      }
    }
  };

  // Rendu du composant Contact
  return (
    <div className="px-4 sm:px-10 md:px-20 lg:px-40 xl:px-60 py-8 bg-gray-50">
      <Helmet>
        <title>Page de contact - Un Monde Qui S'Illumine</title>
        <meta
          name="description"
          content="Bienvenue sur le site Un Monde Qui S'illumine ! Découvrez mes créations et contactez-moi pour plus d'informations."
        />
        <meta
          name="keywords"
          content="objets personnalisés, décorations, créations, un monde qui s'illumine, contact"
        />
      </Helmet>
      <h2 className="text-center text-2xl md:text-3xl font-medium mb-8">
        Besoin d'un renseignement ? Vous voulez passer une commande ?
        Contactez-moi.
      </h2>
      <div className="flex flex-col lg:flex-row justify-center items-start lg:space-x-16">
        <div className="w-full lg:w-1/2 p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-3xl font-medium mb-4 text-center lg:text-left">
            Par mail
          </h2>
          <form
            onSubmit={handleSubmit}
            className="w-full max-w-lg mx-auto lg:mx-0"
          >
            <div className="mb-4">
              <label
                htmlFor="nom"
                className="block text-gray-700 font-bold mb-2"
              >
                Nom et prénom :
              </label>
              <input
                type="text"
                id="nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                className={`shadow appearance-none border rounded w-full py-2 px-3 ${
                  errors.nom ? 'border-red-500' : ''
                }`}
              />
              {errors.nom && (
                <p className="text-red-500 text-xs italic">{errors.nom}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 font-bold mb-2"
              >
                Email :
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`shadow appearance-none border rounded w-full py-2 px-3 ${
                  errors.email ? 'border-red-500' : ''
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-xs italic">{errors.email}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 font-bold mb-2"
              >
                Message :
              </label>
              <textarea
                id="message"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={`shadow appearance-none border rounded w-full py-2 px-3 ${
                  errors.message ? 'border-red-500' : ''
                }`}
              ></textarea>
              {errors.message && (
                <p className="text-red-500 text-xs italic">{errors.message}</p>
              )}
            </div>
            <div className="mb-4 flex justify-center">
              <ReCAPTCHA
                sitekey="6LcJ2nkqAAAAAMxYARlvc8EgETfV7_UttRUko3hP"
                onChange={handleCaptchaChange}
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="mt-8 border border-solid border-black bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-8"
                disabled={isSending}
              >
                {isSending ? 'Envoi en cours...' : 'Envoyer'}
              </button>
            </div>
            {successMessage && (
              <p className="text-green-500 text-center mt-4">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="text-red-500 text-center mt-4">{errorMessage}</p>
            )}
          </form>
        </div>
        <div className="w-full lg:w-1/2 p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-3xl font-medium mb-4 text-center lg:text-left">
            Par courrier
          </h2>
          <address className="not-italic text-xl text-center lg:text-left mb-8">
            Un Monde Qui S'Illumine <br />
            9A rue de la justice <br />
            62880 Vendin-le-Vieil
          </address>
          <h2 className="text-3xl font-medium mb-2 text-center lg:text-left">
            Par téléphone
          </h2>
          <div className="flex items-center justify-center lg:justify-start">
            <FaPhone className="mr-2 text-gray-700" />
            <h2 className="text-xl text-gray-700">06-34-59-09-87</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
